import 'src/subcategories/shortcut_to_design';
import 'src/subcategories/value_adds';
import 'src/shared/tooltip';
import 'src/product_catalog';
import 'src/shared/gtm/listing';

const ecommerce = {
  item_list_id: parseInt($('meta[property=categoryinfo]').prop('id')),
  item_list_name: $('meta[property=categoryinfo]').attr('category_tree'),
  page_type: 'category'
};

jQuery(() => ProductCatalog.gtmEcomEvent('products_subcategories', ecommerce));
